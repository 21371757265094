import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${scroll};
`;

export const Table = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-radius: 8px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #98a9bc;
    height: 48px;
    text-align: left;
    padding: 8px 16px;
    border-bottom: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }
  }

  th[align='center'] {
    text-align: center;
  }
  tr {
    cursor: pointer;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  td {
    overflow: hidden;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    height: 64px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;

    &:last-child {
      border-right: 1px solid #dfdfe8;
      > * {
        margin-left: auto;
        justify-content: flex-end;
        text-align: right;
      }
    }

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }
  }

  td.no-break {
    white-space: nowrap;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DetailsLink = styled.a`
  color: #235dff;
  text-decoration: none;

  &:link,
  &:focus,
  &:hover,
  &:active,
  &:visited {
    color: #235dff;
  }
`;
