import React, { useCallback } from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ImportHistoryListItem, useImportHisotryList } from '@hooks/import/useImportHistoryList';
import moment from 'moment';
import { Briefcase } from 'react-feather';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { useModal } from '@common/PromiseModal';
import { useImportHisotryItemStatus } from '@hooks/import/useImportHistoryItemStatus';
import { ImportHistoryStatus } from '@generated/types/graphql';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { Container, Title, TableContainer, Table, Author, DetailsLink } from './styled';
import { ImportStatus } from '../ImportStatus';
import { Details } from '../Details';
import { useImportCounters } from '../useImportCounters';

const STATUSES_TO_REFETCH = [ImportHistoryStatus.InProgress, ImportHistoryStatus.Undoing, ImportHistoryStatus.Pending];

const TableRow = ({ importHistoryRecord }: { importHistoryRecord: ImportHistoryListItem }) => {
  const { openModal } = useModal();

  useImportHisotryItemStatus(importHistoryRecord.id, {
    isEnabled: STATUSES_TO_REFETCH.includes(importHistoryRecord.status)
  });

  const counters = useImportCounters(importHistoryRecord);

  const handleDetailsClick = useCallback(() => {
    openModal(({ onClose }) => <Details importHistoryRecord={importHistoryRecord} onClose={onClose} />, {
      title: 'Import details'
    });
  }, [importHistoryRecord, openModal]);

  return (
    <tr onClick={handleDetailsClick}>
      <td>
        <Author>
          <UserAvatar user={importHistoryRecord.createdByUser} size={20} />
          <div>
            {importHistoryRecord.createdByUser.firstName} {importHistoryRecord.createdByUser.lastName}
          </div>
        </Author>
      </td>
      <td>
        <ImportStatus status={importHistoryRecord.status} />
      </td>
      <td>{moment(importHistoryRecord.createdAt).format('MMM D, YYYY h:mm a')}</td>
      <td>
        {importHistoryRecord.type === 'PROJECT' && (
          <Badge size={BadgeSize.Large} bgColor="#DFDFE8" color="#1D1D35">
            <Briefcase color="#9C9CAA" size="16px" />
            Projects
          </Badge>
        )}
        {importHistoryRecord.type === 'DEAL' && (
          <Badge size={BadgeSize.Large} bgColor="#DFDFE8" color="#1D1D35">
            <DollarIcon color="#9C9CAA" size="16px" />
            Requests
          </Badge>
        )}
      </td>
      <td align="center">{counters.total}</td>
      <td align="center">{counters.created}</td>
      <td align="center">{counters.updated}</td>
      <td align="center">{counters.skipped}</td>
      <td align="center">{counters.failed}</td>

      <td>
        <DetailsLink>Details</DetailsLink>
      </td>
    </tr>
  );
};

export const History = () => {
  const { data: importHistory = [], isLoading } = useImportHisotryList();

  return (
    <Container>
      <Title>Your Imports</Title>

      <TableContainer>
        <Table>
          <thead>
            <th>Imported by</th>
            <th>Status</th>
            <th>Date</th>
            <th>Type</th>
            <th align="center">Total</th>
            <th align="center">Created</th>
            <th align="center">Updated</th>
            <th align="center">Skipped</th>
            <th align="center">Failed</th>

            <th />
          </thead>
          <tbody>
            {importHistory.map((importHistoryRecord) => (
              <TableRow key={importHistoryRecord.id} importHistoryRecord={importHistoryRecord} />
            ))}

            {!isLoading && importHistory.length === 0 && (
              <tr>
                <td align="center" colSpan={9}>
                  No import records found. Once you&apos;ve imported data, your import history will appear here.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
