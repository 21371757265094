import { ImportHistoryStatus } from '@generated/types/graphql';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import React from 'react';

interface Props {
  status: ImportHistoryStatus;
}

type StatusConfig = {
  backgroundColor: string;
  color: string;
  label: string;
};

const STATUS_CONFIG_MAP: Record<ImportHistoryStatus, StatusConfig> = {
  [ImportHistoryStatus.Cancelled]: {
    backgroundColor: '#E4E8EC',
    color: '#778CA2',
    label: 'Cancelled'
  },
  [ImportHistoryStatus.Failed]: {
    backgroundColor: '#F7DADD',
    color: '#D54855',
    label: 'Failed'
  },
  [ImportHistoryStatus.InProgress]: {
    backgroundColor: '#DBE5FF',
    color: '#4D7CFE',
    label: 'In progress'
  },
  [ImportHistoryStatus.PartiallySuccess]: {
    backgroundColor: '#CDF3DF',
    color: '#009A47',
    label: 'Partially success'
  },
  [ImportHistoryStatus.Pending]: {
    backgroundColor: '#DBE5FF',
    color: '#4D7CFE',
    label: 'Pending'
  },
  [ImportHistoryStatus.Success]: {
    backgroundColor: '#CDF3DF',
    color: '#009A47',
    label: 'Success'
  },
  [ImportHistoryStatus.Undoing]: {
    backgroundColor: '#DBE5FF',
    color: '#4D7CFE',
    label: 'Undoing'
  },
  [ImportHistoryStatus.Undone]: {
    backgroundColor: '#CDF3DF',
    color: '#009A47',
    label: 'Undone'
  }
};

export const ImportStatus = ({ status }: Props) => {
  const statusConfig = STATUS_CONFIG_MAP[status];

  return (
    <Badge size={BadgeSize.Large} bgColor={statusConfig.backgroundColor} color={statusConfig.color}>
      {statusConfig.label}
    </Badge>
  );
};
