import { RecordType } from '@types';
import React, { useMemo } from 'react';
import { ArrowDown, ArrowUp, Edit2, Folder } from 'react-feather';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { isDefaultPropertiesGroup, isNonePropertiesGroup, usePropertyGroupMutation } from '@hooks/usePropertyGroup';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { moveItemInArray } from '@utils/moveItemInArray';
import { GroupName, GroupNameRow, GroupNameTd } from './styled';
import { GroupForm } from './GroupForm';

interface Props {
  scope: RecordType;
  group: { id: number; name: string; position: number };
  groups: { id: number; name: string; position: number }[];
  externalFormEnabled?: boolean;
}

const isNotEditableGroup = (group: { id: number }) => isDefaultPropertiesGroup(group) || isNonePropertiesGroup(group);

export const GroupRow = ({ externalFormEnabled, scope, group, groups }: Props) => {
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();
  const {
    updateLayout: { mutateAsync: updateLayout },
    remove: { mutateAsync: remove }
  } = usePropertyGroupMutation();

  const groupMenuItems = useMemo<MenuItem[]>(() => {
    if (isNotEditableGroup(group)) {
      return [];
    }

    return [
      group.position !== 1 && {
        icon: <ArrowUp size="16px" color="#9C9CAA" />,
        title: 'Move up',
        onClick: async () => {
          await updateLayout({
            dto: {
              scope,
              groupIds: moveItemInArray(groups, group.position, group.position - 1)
                .map((group) => group.id)
                .filter((id) => !isNotEditableGroup({ id }))
            }
          });
        }
      },
      group.position !== groups.length - 2 && {
        icon: <ArrowDown size="16px" color="#9C9CAA" />,
        title: 'Move down',
        onClick: () => {
          updateLayout({
            dto: {
              scope,
              groupIds: moveItemInArray(groups, group.position, group.position + 1)
                .map((group) => group.id)
                .filter((id) => !isNotEditableGroup({ id }))
            }
          });
        }
      },
      group.position !== 1 && {
        icon: <ChevronsUpIcon size="16px" color="#9C9CAA" />,
        title: 'Move to the top',
        onClick: async () => {
          await updateLayout({
            dto: {
              scope,
              groupIds: moveItemInArray(groups, group.position, 1)
                .map((group) => group.id)
                .filter((id) => !isNotEditableGroup({ id }))
            }
          });
        }
      },
      group.position !== groups.length - 2 && {
        icon: <ChevronsDownIcon size="16px" color="#9C9CAA" />,
        title: 'Move to the bottom',
        onClick: () => {
          updateLayout({
            dto: {
              scope,
              groupIds: moveItemInArray(groups, group.position, groups.length - 2)
                .map((group) => group.id)
                .filter((id) => !isNotEditableGroup({ id }))
            }
          });
        }
      },
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          openModal<void>(({ onClose }) => <GroupForm initialValues={group} scope={scope} onClose={onClose} />, {
            title: 'Edit property group'
          });
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this group?')) {
            await remove({ groupId: group.id });
          }
        }
      }
    ].filter(Boolean);
  }, [openModal, scope, group, remove, confirmDelete, groups, updateLayout]);

  return (
    <GroupNameRow key={group.id}>
      <GroupNameTd colSpan={externalFormEnabled ? 6 : 5}>
        <GroupName>
          <Folder size="24px" color="#9C9CAA" />
          {group.name}
        </GroupName>
      </GroupNameTd>
      <td>{groupMenuItems.length > 0 && <ContextMenu items={groupMenuItems} />}</td>
    </GroupNameRow>
  );
};
