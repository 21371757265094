import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Edit3, Plus, RotateCw } from 'react-feather';
import { useEmailAccountList } from '@hooks/workspace/emails/useEmailAccountList';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { EmailAccount, EmailAccountStatus } from '@generated/types/graphql';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useDisconnectEmailAccount } from '@hooks/workspace/emails/useDisconnectEmailAccount';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useConnectEmailAccount } from '@hooks/workspace/emails/useConnectEmailAccount';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { Status } from './Status';
import { ConnectEmailForm } from './ConnectEmailForm';
import { StatusAndReconnect } from './styled';
import { Table, TableContainer, TableHeader, TableTitle } from '../styled';

const TableRow = ({ emailAccount }: { emailAccount: EmailAccount }) => {
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const { mutateAsync: connect } = useConnectEmailAccount();

  const { mutateAsync: disconnect } = useDisconnectEmailAccount();

  const handleReconnectClick = useCallback<React.MouseEventHandler<SVGElement>>(
    async (e) => {
      e.stopPropagation();

      const url = await connect({
        email: emailAccount.emailAddress,
        redirectTo: `${window.location.pathname}?email=${emailAccount.emailAddress}`,
        alias: emailAccount.alias,
        accountId: emailAccount.id
      });

      window.location.href = url;
    },
    [emailAccount, connect]
  );

  const handleRowClick = useCallback(() => {
    openModal<void>(({ onClose }) => <ConnectEmailForm initialValues={emailAccount} onClose={onClose} />, {
      title: 'Edit Email'
    });
  }, [emailAccount, openModal]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit3 size="16px" color="#98A9BC" />,
        onClick: handleRowClick
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,
        onClick: async () => {
          if (
            await confirmDelete(
              `You're about to disconnect email account ${emailAccount?.emailAddress}. \n
                    This will result in all emails sent to and from this account to be removed from Coperniq Clients and
                    Projects.`,
              'Disconnect'
            )
          ) {
            await disconnect(emailAccount.id);
          }
        }
      }
    ];
  }, [emailAccount, handleRowClick, confirmDelete, disconnect]);

  return (
    <tr onClick={handleRowClick}>
      <td>{emailAccount.alias}</td>
      <td>
        <EmailAddress isCopyAllowed value={emailAccount.emailAddress} />
      </td>
      <td>
        <StatusAndReconnect>
          <Status status={emailAccount.status} />

          {emailAccount.status === EmailAccountStatus.Invalid && (
            <RotateCw size="16px" color="#98A9BC" onClick={handleReconnectClick} />
          )}
        </StatusAndReconnect>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};

export const Emails = () => {
  const { data: emailAccounts = [], isLoading } = useEmailAccountList();

  const { openModal } = useModal();

  const handleAddClick = useCallback(() => {
    openModal<void>(({ onClose }) => <ConnectEmailForm onClose={onClose} />, { title: 'Connect Email' });
  }, [openModal]);

  const [emailFromUrl, setEmailInUrl] = useQueryParam(QueryParamsEnum.Email);

  useEffect(() => {
    if (!emailAccounts.length || !emailFromUrl) {
      return;
    }

    const emailAccount = emailAccounts.find((e) => e.emailAddress === emailFromUrl);

    if (emailAccount) {
      openModal<void>(({ onClose }) => <ConnectEmailForm initialValues={emailAccount} onClose={onClose} />, {
        title: 'Update Email Account'
      }).then(() => {
        setEmailInUrl(undefined);
      });
    } else {
      setEmailInUrl(undefined);
    }
  }, [emailAccounts, emailFromUrl, setEmailInUrl, openModal]);

  return (
    <div>
      <TableHeader>
        <TableTitle>Emails</TableTitle>
        <Button onClick={handleAddClick} variant={ButtonVariant.Primary}>
          <Plus size="16px" />
          Email
        </Button>
      </TableHeader>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {emailAccounts.map((emailAccount) => (
              <TableRow key={emailAccount.id} emailAccount={emailAccount} />
            ))}

            {!isLoading && emailAccounts.length === 0 && (
              <tr>
                <td colSpan={4} align="center">
                  No email accounts connected yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
